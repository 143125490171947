/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title, image, video }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || require('../images/cover.png');

  const sizeCard = video ? "player": "summary_large_image";

  console.log(1919, title);
  console.log(2424, video)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: "https://andreamunegato.it/works/despar_blink"
        },
        {
          property: `url`,
          content: "https://andreamunegato.it/works/despar_blink"
        },

        {
          property: `image`,
          content: video && metaImage
        },
        {
          property: `og:image`,
          content: video && metaImage
        },
        {
          property: `og:image:width`,
          content: "1280"
        },
        {
          property: `og:image:height`,
          content: "720"
        },
        {
          property: `video`,
          content: video
        },
        {
          property: `og:video`,
          content: video
        },
        {
          property: `og:video:url`,
          content: video
        },
        {
          property: `og:video:secure_url`,
          content: video
        },

        // {
        //   property: `og:video:type`,
        //   content: "video.other"
        // },
        {
          property: `og:video:width`,
          content: "1280"
        },
        {
          property: `og:video:height`,
          content: "720"
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: video ? `video.other` : `website`,
        },
        {
          property: `og:site_name`,
          content: "Andrea Munegato",
        },
        {
          name: `twitter:card`,
          content: sizeCard,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `it`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
