import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, Link, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"

import logo from "../images/AM_New_Logo.svg"
import icDrawer from "../images/ic_drawer.svg"
import icClose from "../images/ic_close.svg"

import facebook from "../images/ic_facebook.svg"
import instagram from "../images/ic_instagram.svg"
// import dribbble from "../images/ic_dribbble.svg"
import behance from "../images/ic_behance.svg"
import linkedin from "../images/ic_linkedin.svg"

export const vBandWidth = 150
export const vBandPadding = 30

const PageLayout = ({
  children,
  selectedPage,
  renderCenterNavBar,
  renderEndNavBar,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const padding = vBandPadding

  const NavButton = ({ page, path }) => (
    <Link to={path || `/${page}`}>
      <Grid
        item
        className={"nav-button" + (path === selectedPage ? " selected" : "")}
        children={page}
      />
    </Link>
  )

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  return (
    <Grid {...props} container direction="row">
      <div className={`mobile-drawer ${open ? "open" : ""}`} data-hidden="mdUp">
        <Grid container direction="column">
          <Grid item xs={12} className="section">
            <Link to="/" onClick={closeDrawer}>
              <h2 className="section-item" style={{ transitionDelay: "150ms" }}>
                Home
              </h2>
            </Link>
          </Grid>
          <Grid item xs={12} className="section">
            <Link to="/gallery" onClick={closeDrawer}>
              <h2 className="section-item" style={{ transitionDelay: "250ms" }}>
                Gallery
              </h2>
            </Link>
          </Grid>
          <Grid item xs={12} className="section">
            <Link to="/about" onClick={closeDrawer}>
              <h2 className="section-item" style={{ transitionDelay: "350ms" }}>
                About
              </h2>
            </Link>
          </Grid>
          <Grid item xs={12} className="section">
            <a href="mailto:info@andreamunegato.it">
              <h2 className="section-item" style={{ transitionDelay: "450ms" }}>
                Contact
              </h2>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            className="drawer-icons"
            style={{ transitionDelay: "550ms" }}
          >
            <a
              href="https://facebook.com/andrea.munegato.7"
              target="_blank"
              className="social-link"
            >
              <img className="social-icon" src={facebook} alt="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/andreamunegato3d/"
              target="_blank"
              className="social-link"
            >
              <img className="social-icon" src={instagram} alt="social-icon" />
            </a>
            {/* Dribbble no perchè non ci sono immagini */}
            <a
              href="https://www.behance.net/info52a19ce7"
              target="_blank"
              className="social-link"
            >
              <img className="social-icon" src={behance} alt="social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/andrea-munegato-063330107/"
              target="_blank"
              className="social-link"
            >
              <img className="social-icon" src={linkedin} alt="social-icon" />
            </a>
          </Grid>
        </Grid>
      </div>

      {/* LEFT COLUMN PLACEHOLDER */}
      <Grid
        container
        direction="column"
        className="band"
        data-hidden="smDown"
      />

      {/* CONTENT */}
      <Grid item xs>
        <Grid container direction="column">
          <main>{children}</main>
        </Grid>
      </Grid>

      {/* RIGHT COLUMN PLACEHOLDER */}
      <Grid
        container
        direction="column"
        className="band"
        data-hidden="smDown"
      />

      {/* LEFT COLUMN */}
      <Grid
        container
        justify="space-between"
        alignItems="center"
        direction="column"
        className="lateral-col left"
        data-hidden="smDown"
      >
        <Link to="/">
          <div style={{ width: 100, height: 100 }}>
            <img src={logo} />
          </div>
        </Link>

        <Grid container direction="column" alignItems="center">
          <a
            href="https://facebook.com/andrea.munegato.7"
            target="_blank"
            className="social-link"
          >
            <img className="social-icon" src={facebook} alt="social-icon" />
          </a>
          <div className="divider" />
          <a
            href="https://www.instagram.com/andreamunegato3d/"
            target="_blank"
            className="social-link"
          >
            <img className="social-icon" src={instagram} alt="social-icon" />
          </a>
          <div className="divider" />
          {/* Dribbble no perchè non ci sono immagini */}
          <a
            href="https://www.behance.net/info52a19ce7"
            target="_blank"
            className="social-link"
          >
            <img className="social-icon" src={behance} alt="social-icon" />
          </a>
          <div className="divider" />
          <a
            href="https://www.linkedin.com/in/andrea-munegato-063330107/"
            target="_blank"
            className="social-link"
          >
            <img className="social-icon" src={linkedin} alt="social-icon" />
          </a>
        </Grid>
      </Grid>

      {/* RIGHT COLUMN */}
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        alignItems="stretch"
        className="lateral-col right"
        data-hidden="smDown"
      >
        {/*wip*/}
        <Grid container justify="center" alignContent="flex-start">
          {/* <div
            style={{
              position: "relative",
              width: 100,
              height: 100,
              border: "1px solid yellow",
              zIndex: 10,
            }}
          /> */}
        </Grid>

        <Grid container justify="center" alignContent="center">
          {renderCenterNavBar && renderCenterNavBar()}
        </Grid>

        <Grid container justify="center" alignContent="flex-end">
          {renderEndNavBar && renderEndNavBar()}
        </Grid>
      </Grid>

      {/* NAV BAR */}
      <Grid
        container
        direction="row"
        style={{
          position: "fixed",
          top: padding,
          right: vBandWidth + padding,
          width: "auto",
          zIndex: 10,
          //border: '1px solid green'
        }}
        data-hidden="smDown"
      >
        <NavButton page="home" path="/" />
        <NavButton page="gallery" path="/gallery" />
        <NavButton page="about" path="/about" />
        {/*<NavButton page="contacts2" path="/contacts/" />*/}
        <a href="mailto:info@andreamunegato.it">
          <Grid item className="nav-button" children="contacts" />
        </a>
      </Grid>

      {/* MOBILE NAV */}
      <Grid
        container
        direction="row"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: 64,
          backgroundColor: "#070707",
          zIndex: 20,
          //border: '1px solid green'
        }}
        data-hidden="mdUp"
      >
        <Link
          to="/"
          children={<img src={logo} style={{ width: 64, height: 64 }} />}
        />
        <div
          className={`drawer-icon-container ${!open ? 'visible' : ''}`}
          onClick={toggleDrawer}
        >
          <img
            src={icDrawer}
            className="drawer-icon"
            alt="social-icon"
          />
        </div>
        <div
          className={`drawer-icon-container ${open ? 'visible' : ''}`}
          onClick={toggleDrawer}
        >
          <img
            src={icClose}
            className="drawer-icon"
            alt="social-icon"
          />
        </div>
      </Grid>
    </Grid>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
