/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, Link, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"

import Transition from "../components/transition"
import Helmet from "react-helmet"

import Header from "../components/header"
import "./layout.css"
import PageLayout from "../components/page_layout"
import SEO from "../components/seo"

//Note that we need to pass location to our functional component  so we have access to it down there in <Transition/>
const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content:
                "3D Artist & Motion Grapher. Creo render fotorealistici, animazioni video in 3D e motion graphic, realizzo esperienze web 3D interattive per il tuo brand, prototipo le tue idee tramite stampa 3D.",
            },
            {
              name: "keywords",
              content: [
                "3dartist",
                "3dmodeling",
                "3d",
                "photorealism",
                "shading",
                "productstudy",
                "3drender",
                "motiongraphics",
                "motiongraphicsdesign",
                "video",
                "videomaking",
                "3danimation",
                "animation",
              ],
            },
          ]}
        />

        <PageLayout selectedPage={location && location.pathname}>
          <Transition location={location}>{children}</Transition>
        </PageLayout>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
